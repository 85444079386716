import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const DigitalSolutions = () => {
    return (
        <Layout>
            <Seo title="Digital Solutions" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Digital Solutions</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h2>Delta Connect</h2>
                        <p>
                            We all buy goods and services online. Buying marine fuel with Delta is no different. We use our platform,
                            Delta Connect, to accomodate this process.
                        </p>
                        <h2>Procurement</h2>
                        <p>
                            Supercharge your marine fuel procurement by standardizing and automating repetitive tasks in your RFQ and
                            offer comparison process. Manage procurement your way: whether you deal directly with physical suppliers,
                            preferred brokers and traders, or use our global coverage to serve you.
                        </p>
                        <h2>Orders</h2>
                        <p>
                            Track the delivery and invoicing status, manage your disputes with suppliers. Automatically inform your
                            vessel and agent. The system is a logical archive for documents like BDNs. Approvals and workflows can be
                            customized to your organization. We unburden you so you can focus on what matters.
                        </p>
                        <h2>Reporting</h2>
                        <p>
                            Management reports, overviews, and analytics are available in your preferred format. You can export all
                            your pricing, volume, and other data to Excel, or generate PowerBI and PDF reports. You can view them
                            integrally in the platform or download and share them. The format and content of the reports are fully
                            customized to your requirements.
                        </p>
                        <h2>Quality control</h2>
                        <p>
                            We understand the vitality of quality fuels to your business. The platform features a continuously updated
                            overview of quality reports from all your laboratories combined. The insights in density differences and
                            supplier quality performance help you to keep a sharp eye on quality - and the power to make the right
                            purchasing decisions and put off-spec suppliers on notice.
                        </p>
                        <h2>Benchmarking</h2>
                        <p>
                            Compare your price performance against benchmarks like Platts, ICE, or your preferred pricing source, and
                            apply the right corrections easily and quickly. With the platform’s reporting capabilities you’ll be able
                            to generate any format to support your management requirements.
                        </p>
                        <h2>Security</h2>
                        <p>
                            Your data is fully encrypted by Microsoft, both in transit and in rest. You can rest assured that we use
                            the state-of-the-art security and support HTTPS, password hashing and salting and limited access token
                            lifetimes. For additional security of your private accounts you can enable Two-Factor Authentication (2FA)
                            with Microsoft or Google Authenticator.
                        </p>
                        <h2>Integration</h2>
                        <p>
                            The Delta Connect platform integrates seamlessly with fleet and voyage software like Veson, BASSnet, and
                            Dataloy. We can exchange administrative information directly with your accounting and enterprise software.
                            Voyage information is automatically copied into your bunker requests, and invoices are pushed back to the
                            system of your choice.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DigitalSolutions
